<template>
  <div v-if="proposal">
    <v-card flat class="auto elevation-4" height="100%">
      <v-card-text class="obj-title">
        <span class="title">{{ proposal.object }}</span>
        <v-btn x-small text color="accent" class="subaccent date-pub rounded-lg">{{
          formattedDate(proposal.created_at).date }}</v-btn>
      </v-card-text>

      <v-card-text class="h-vac-content" v-html="proposal.content"></v-card-text>
      <v-card-text>
        <!-- <span class="secondary--text">Skills required:</span> <span class="error-text">No specified</span> -->
        <!-- <span class="ms-2 subprimary over-rounded">front dev </span>
            <span class="ms-2 subprimary over-rounded">full stack</span> -->
      </v-card-text>
      <v-card-actions>
        <v-btn small outlined color="primary" class="subprimary" @click="viewProposalsDetails">View</v-btn>
        <v-btn small outlined color="secondary" class="subsecondary" @click="EditProposals">Edit</v-btn>
        <v-btn small outlined color="error" class="suberror" @click="deleteProposals">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mdiOpenInNew } from '@mdi/js'
var md5 = require("md5")

export default {
  props: {
    proposal: {
      type: Object,
      default: () => { }
    },
  },

  methods: {

    formattedDate(datetime) {
      const date = new Date(datetime);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return { 'date': `${day}/${month}/${year}`, 'time': `${hours}:${minutes}` };
    },
    goToreferenceLink(value) {
      this.$router.push(link)
    },
    EditProposals() {
      this.$router.push({ name: 'company.rfp.edit', params: { company: this.$store.getters.getCompany.name, value: this.proposal.id, title: md5(this.proposal.id + 'l') } })
    },
    viewProposalsDetails() {
      // this.$router.push({ name: 'proposal.details', params: { offer_title: this.proposal.object, offer_id: this.proposal.id } })

      // this.$store.commit("setViewrequest", {
      //           inbox: true,
      //           owner: 'company',
      //           type: 'RFP',
      //           id: this.proposal.id
      //       });
      this.$router.push({name: 'proposal.details', params: { offer_title: this.proposal.object, offer_id: this.proposal.id }
      })
      // this.$router.push({ name: 'c.request.detail', 
      //               params: {
      //                   company: this.$store.getters.getCompany.name, ids: this.proposal.id, f_id: md5(this.proposal.id + 'l')
      //               }})
      // ,params: { offer_title: this.proposal.object, offer_id: this.proposal.id } })
    },
    deleteProposals() { }
  },
  mount() {
    console.log(this.proposal)
  },
  setup() {
    return {
      icons: {
        mdiOpenInNew
      },
    }
  },
}
</script>

<style lang="scss">
//@override
.v-card {
  border-radius: 1em !important;
}

.obj-title {
  display: flex;
  flex-wrap: nowrap !important;
  justify-content: space-between;
  flex-direction: row;
}

.obj-title .title {
  font-size: 11pt !important;
  color: var(--v-secondary-base);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.obj-title .date-pub {
  font-size: 8pt !important;
  margin-left: 4px !important;
  padding: 0px 3px !important;
}

.h-vac-content {
  height: 4em !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-vacancie {
  width: 100%;
  display: block;
}
</style>