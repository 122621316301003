<template>
  <div v-if="vacancies">
    <v-card flat class="auto elevation-4" height="100%">
      <v-card-text class="obj-title">
        <v-list-item three-line class="text-top">
          <v-list-item-avatar size="60" tile class="secondary">
            <!-- <v-img src="@/assets/images/avatars/profil.svg"></v-img> -->
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title><strong>{{ vacancies.title }}</strong></v-list-item-title>
            <v-list-item-subtitle>{{ vacancies.company.name }}</v-list-item-subtitle>
            <v-list-item-subtitle>
              <div class="d-flex flex-row w-auto">
                <v-icon size="1.2em" class="mr-2">{{ icons.mdiMapMarkerOutline }}</v-icon>
                <span class="mr-2">{{ vacancies.location }}</span>
                <!-- <span>{{ vacancies.location + ', ' + vacancies.country.name }}</span> -->
                <v-img min-width="1.6em" max-width="1.6em" width="1.6em" height="1em" aspect-ratio="1"
                  :src="require('@/assets/images/flags/' + vacancies.country.iso + '.svg')"
                  :lazy-src="'@/assets/images/flags/' + vacancies.country.iso + '.svg'">
                  <template v-slot:placeholder>
                    <div class="fill-height ma-0" align="center" justify="center">
                      <v-skeleton-loader type="image" height="1em" width="1.5em"
                        class="mx-auto rounded-pill"></v-skeleton-loader>
                    </div>
                  </template>
                </v-img>
              </div>
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <span class="mr-4"> {{ vacancies.periodicity }}</span>
              <span class="error--text"> Deadline: {{ vacancies.dateline }}</span>
            </v-list-item-subtitle>
            <!-- <v-card-text class="px-0 h-job-content" height="1em" v-html="vacancies.description"></v-card-text> -->
          </v-list-item-content>
        </v-list-item>
      </v-card-text>
      <v-card-actions>
        <v-btn small outlined color="primary" class="subprimary" @click="viewVacanciesDetails">View</v-btn>
        <v-btn small outlined color="secondary" class="subsecondary" @click="EditVacancies">Edit</v-btn>
        <v-btn small outlined color="error" class="suberror" @click="deleteVacancies">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mdiOpenInNew, mdiMapMarkerOutline } from '@mdi/js'
var md5 = require("md5")

export default {
  props: {
    vacancies: {
      type: Object,
      default: () => { }
    },
  },

  methods: {

    formattedDate(datetime) {
      const date = new Date(datetime);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return { 'date': `${day}/${month}/${year}`, 'time': `${hours}:${minutes}` };
    },
    goToreferenceLink(value) {
      this.$router.push(link)
    },
    EditVacancies() {
      this.$router.push({ name: 'company.vacancies.edit', params: { jobid: this.vacancies.id, title: md5(this.vacancies.id + 'l') } })
    },
    viewVacanciesDetails() {
      this.$router.push({ name: 'company.vacancies.details', params: { jobid: this.vacancies.id, title: md5(this.vacancies.id + 'l') } })
    },
    deleteVacancies() { }
  },
  mount() {
    console.log(this.vacancies)
  },
  setup() {
    return {
      icons: {
        mdiOpenInNew, mdiMapMarkerOutline
      },
    }
  },
}
</script>

<style lang="scss">
//@override
.v-card {
  border-radius: 1em !important;
}

.obj-title {
  display: flex;
  flex-wrap: nowrap !important;
  justify-content: space-between;
  flex-direction: row;
}

.obj-title .title {
  font-size: 11pt !important;
  color: var(--v-secondary-base);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.obj-title .date-pub {
  font-size: 8pt !important;
  margin-left: 4px !important;
  padding: 0px 3px !important;
}

.h-vac-content {
  height: 4em !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-vacancie {
  width: 100%;
  display: block;
}
</style>